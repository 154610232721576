body {
  margin: 0;
  font-family: Roboto;
  background: #ffffff;
  width: 100%;
  height: 100%;
}


::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #1f1f1f;
}
::-webkit-scrollbar-thumb {
  background: #575e62;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(105, 73, 141, 0.527);
}

.your-label-class .MuiInput-input {
  font-size: 45px;
}